html,
body {
  margin: 0;
  padding: 0;
  overflow-x: hidden;
  width: 100vw;
  letter-spacing: 0.05rem;
  background-color: rgb(0, 0, 0);
}

@import url("https://rsms.me/inter/inter.css");
html {
  font-family: "Inter", sans-serif;
}

@supports (font-variation-settings: normal) {
  html {
    font-family: "Inter var", sans-serif;
  }
}

:root {
  --mainBackground: rgb(55, 0, 117);
  --secondBackground: rgb(21, 192, 109);
  --mainColor: rgb(120, 2, 254);
  --secondColor: rgba(54, 229, 144);
  --black: #000;
  --lessBlack: rgb(48, 48, 48);
  --verySmallText: 14px;
  --SmallSmallText: 14px;
  --SmallText: 16px;
  --regularText: 18px;
  --regularBigText: 20px;
  --bigText: 34px;
  --veryBigText: 4rem;
  --regularWeight: 300;
  --strongWeight: 600;
}
/* 
@media screen and (min-width: 300px) and (max-width: 600px) {
  :root {
    --verySmallText: 0.6rem;
    --SmallSmallText: 0.8rem;
    --SmallText: 1rem;
    --regularText: 1.2rem;
    --regularBigText: 1.4rem;
    --bigText: 1.6rem;
    --veryBigText: 3rem;
    --regularWeight: 300;
    --strongWeight: 600;
  }
}

@media screen and (min-width: 601px) and (max-width: 900px) {
  :root {
    --verySmallText: 0.9rem;
    --SmallSmallText: 1rem;
    --SmallText: 1.1rem;
    --regularText: 1.2rem;
    --regularBigText: 1.4rem;
    --bigText: 1.6rem;
    --veryBigText: 3rem;
    --regularWeight: 300;
    --strongWeight: 600;
  }
}

@media screen and (min-width: 901px) and (max-width: 1200px) {
  :root {
    --verySmallText: 1rem;
    --SmallSmallText: 1.1rem;
    --SmallText: 1.2rem;
    --regularText: 1.4rem;
    --regularBigText: 1.6rem;
    --bigText: 1.8rem;
    --veryBigText: 3rem;
    --regularWeight: 300;
    --strongWeight: 600;
  }
}

 */