.timelineInstance {
  display: flex;
  margin: 0;
}

.timelineInstance-left {
  padding: 1rem;

  width: 20%;
  height: auto;
}

.timelineInstance-right {
  font-weight: var(--regularWeight);
  width: 70%;
  padding: 2vw;
  padding-top: 0rem;
  padding-bottom: 0rem;
  display: flex;
  justify-content: flex-start;
  flex-direction: column;
}

.timelineInstance-left-img {
  width: 100%;
  max-width: 600px;
  height: auto;
  border-radius: 0.5vw;
}

.timelineInstance-name {
  margin-top: 1rem;
  margin-bottom: 1rem;
  font-size: var(--bigText);
}

.timelineInstance-company-and-type,
.timelineInstance-location-and-time {
  display: flex;
  justify-content: space-between;
  margin-bottom: 1rem;
}

.timelineInstance-company-and-type {
  font-size: var(--SmallText);
}

.timelineInstance-location-and-time {
  font-size: var(--SmallSmallText);
}

.timelineInstance-name-container,
.timelineInstance-lessons-container {
  display: flex;
  justify-content: center;
  align-items: center;
}

.timelineInstance-type {
  margin: 0;
  font-weight: var(--strongWeight);
}

.timelineInstance-time {
  margin: 0;
  font-weight: var(--SmallSmallText);
}

.timelineInstance-location {
  margin: 0;
  font-weight: var(--regularWeight);
}

.timelineInstance-company {
  margin: 0;
  font-weight: var(--strongWeight);
}

.timelineInstance-list {
  font-size: var(--SmallSmallText);
}

.timelineInstance-lessons {
  font-weight: var(--strongWeight);
  font-size: var(--SmallSmallText);
  margin-bottom: 0;
}
.timelineInstance-header-img {
  display: none;
}
@media screen and (min-width: 300px) and (max-width: 1200px) {
  .timelineInstance {
    /*     flex-direction: column;
    justify-content: center;
    align-items: center; */
  }

  .timelineInstance-right {
    width: 90%;
  }
}

@media screen and (min-width: 300px) and (max-width: 501px) {
  .timelineInstance-left {
    display: flex;
    justify-content: center;
    align-items: center;
    width: 90%;
  }
  .timelineInstance {
    flex-direction: column;
    justify-content: center;
    align-items: center;
  }
  .timelineInstance-left {
    display: none;
  }
  .timelineInstance-header-img{
    display: block;
   width: 40%;
   padding: 1rem;
   padding-left: 0rem;
  }
}
/* 
@media screen and (min-width: 501px) and (max-width: 800px) {
  .timelineInstance-left {
    display: flex;
    justify-content: center;
    align-items: center;
    width: 50%;
  }
}

@media screen and (min-width: 801px) and (max-width: 1200px) {
  .timelineInstance-left {

    width: 50%;
  }
}
 */
