.skillContainer {
  width: 100%;
  display: flex;
  justify-content: center;
  flex-direction: column;
  align-items: center;
  gap: 4rem;
  padding: 5rem 0rem 40rem 0rem;
  background: linear-gradient(170deg, var(--mainColor) 70%, black);
  position: relative;
}

.skillSection {
  width: 80%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

.skillSection h3 {
  color: white;
  font-size: var(--veryBigText);
  margin-bottom: 2rem;
  text-shadow: 1px 1px 7px black;
}

.skillRow {
  display: flex;
  flex-direction: row;
  gap: 2vw;
  flex-wrap: wrap;
  justify-content: center;
  align-items: center;
}
@media screen and (min-width: 300px) and (max-width: 500px) {

}
@media screen and (min-width: 300px) and (max-width: 600px) {
  .skillRow {
    gap: 5vw;
  }
  .skillSection {
    width: 95%;
  }
  .skillSection h3{
    font-size: var(--bigText);
  }
}

@media screen and (min-width: 300px) and (max-width: 1000px) {
  .skillContainer {
    padding-bottom: 20rem;
  }
}