.skill {
  background: linear-gradient(5deg, black, rgb(44, 44, 44));
  max-width: 400px;
  min-height: 200px;
  border: 0.5rem rgb(0, 0, 0) solid;
  color: white;
  display: flex;
  transition: all 0.3s;
  border-radius: 0.5rem;
  box-shadow: rgba(0, 0, 0, 0.708) 8px 8px 20px;
}

.skill:hover {
  transform: scale(1.03);
}

.skill-left {
  width: 50%;
  border-radius: 1vw;
}

.skill-left img {
  width: 100%;
  height: 100% ;

}

.skill-right {
  width: 50%;
  display: flex;
  align-items: center;

  flex-direction: column;
}

.skill-right h3 {
  font-size: var(--regularBigText);
  margin: 0;
  margin-top: 1rem;
}

.skill-right ul {
  margin: 0;
  padding: 0rem 0rem 0rem 2rem;
}

.skill-right ul li {
  margin: 0rem 0rem 0.4rem 0rem;
  word-wrap: break-word;
  font-weight: var(--regularWeight);
  font-size: var(--verySmallText);
}

a {
  font-weight: var(--strongWeight);
  color: white;
  font-size: var(--SmallSmallText);
  text-decoration: none;
}

@media screen and (min-width: 300px) and (max-width: 800px) {
  .skill {
    flex-direction: row;
    /* justify-content: center;
    align-items: center; */
    max-width: 150px;
    min-height: 150px;
    
  }
  .skill-left {
    width: 100%;
  }
  .skill-right ul li{
    font-size: var(--SmallText);
  }
 
  .skill-right{
    width: 100%;
    display: none;
  }
  .skill:hover > .skill-right,  .skill:active > .skill-right{
    display: block;
    display: flex;
    justify-content: center;
    align-items: center; 
    flex-direction: column;
  }
  .skill:hover,  .skill:active {
    
    flex-direction: column;
    max-width: 300px;
    min-height: 400px;
  }

}
