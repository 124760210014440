.projectItem {
  margin: auto;
/*   background: linear-gradient(45deg, black, rgb(44, 44, 44)); */
background-color: rgba(255, 255, 255, 0.888);
/*   color: rgb(188, 188, 188); */
  box-shadow: rgba(0, 0, 0, 0.708) 8px 8px 20px;
  transition: all 0.2s;
  border-top-right-radius: 0.5rem;
  border-top-left-radius: 0.5rem;
  border-bottom-left-radius: 0.5rem;
  border-bottom-right-radius: 0.5rem;
  cursor: pointer;
  color: black;
  margin-bottom: 2rem;
  
}
.projectItem h3{
  text-align: none;
  text-shadow: none;
}
.projectItem:hover {
  /* color: rgb(255, 255, 255); */
  color: black;
  transform: scale(1.01);
}

.itemContent {
  height: auto;
  display: flex;
  border-top-right-radius: 0.5rem;
}

.itemContent-right {
  flex-direction: row-reverse;
  justify-content: space-between;
}

.itemContent-left {
  flex-direction: reverse;
}


.imageContainer::after {
  content: "";
  display: block;
  padding-bottom: 100%;
}

.image-left {
  position: absolute;
  width: 100%;
  height: 100%;
  object-fit: cover;
  border-top-left-radius: 0.5rem;
  border-bottom-left-radius: 0.5rem;
}

.image-right {

  position: absolute;
  width: 100%;
  height: 100%;
  object-fit: cover;
  border-top-right-radius: 0.5rem;
  border-bottom-right-radius: 0.5rem;
}

.image-border-bot {
  border-bottom-left-radius: 0rem;
  border-bottom-right-radius: 0rem;
}
.imageContainer {
  position: relative;
  min-width: 20vw;
  border-top-left-radius: 0.5rem;
}
.arrow-container {
  margin-top: 2rem;
}

.rightPart {
  width: 70%;
  display: flex;
  flex-direction: column;
  padding: 1rem 2rem;
}

.rightPartTitle {
  font-size: var(--bigText);
  margin: 0;
}

.rightPartTheme {
  font-size: var(--regularBigText);
  margin: 0;
 /*  color: rgb(188, 188, 188); */
 color: black;
}

.rightPartDescription {
  font-size: var(--regularText);
}

.collapsible .content {
  padding: 1rem;
  background: linear-gradient(45deg, black, rgba(0, 0, 0, 0.6));
  margin-top: 0rem;
}

.collapsible .header {
  cursor: pointer;
}

.collapseField {
  height: 4rem;
  margin-top: -4rem;
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
}

.content {
  border-bottom-left-radius: 0.5rem;
  border-bottom-right-radius: 0.5rem;
}

@media screen and (min-width: 300px) and (max-width: 1000px) {
  

}

@media screen and (min-width: 300px) and (max-width: 849px) {
  .projectItem-big {
    width: 95%;
  }

  .projectItem-small {
    width: 90%;
  }
  .imageContainer {
    display: none;
  }
  .rightPart {
    width: 100%;
    display: flex;
    flex-direction: column;
    padding: 1rem 2rem;
  }
  .imageContainer {
    position: relative;
    min-width: 20%;
    border-top-left-radius: 0.5rem;
  }
  
}

@media screen and (min-width: 850px) and (max-width: 1200px) {
  .projectItem-big {
    width: 90%;
  }

  .projectItem-small {
    width:80%;
  }
  .imageContainer {
    position: relative;
    min-width: 30%;
    border-top-left-radius: 0.5rem;
  }
  
}


@media screen and (min-width: 1201px) {
  .projectItem-big {
    width: 90%;
  }

  .projectItem-small {
    width: 50%;
  }

  
}
@media screen and (min-width: 1700px) {
  .projectItem-big {
    width: 90%;
  }

  .projectItem-small {
    width: 50%;
  }
  .imageContainer {
    position: relative;
    min-width: 20%;
    border-top-left-radius: 0.5rem;
  }
  
}
