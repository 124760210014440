.titleDiv {
  padding: 2rem;
  height: 0vh;
  margin-top: 20vh;
}

h3 {
  text-shadow: 1px 1px 7px black;
}

h1 {
  font-size: 5vw;
  text-align: center;
  font-weight: 600;
  color: #353239;
}

h1 span span {
  position: relative;
  bottom: 0em;
  opacity: 0;
  animation: move-text 0.3s forwards;
}

@keyframes move-text {
  0% {
    color: rgb(173, 173, 173);
    opacity: 0;
  }

  100% {
    color: rgb(255, 255, 255);
    opacity: 1;
  }
}

@media screen and (min-width: 1px) and (max-width: 350px) {
  h1 {
    font-size: 10vw;
    margin-top: 10%;
  }
}

@media screen and (min-width: 351px) and (max-width: 400px) {
  h1 {
    font-size: 10vw;
    margin-top: 15%;
  }
}

@media screen and (min-width: 401px) and (max-width: 700px) {
  h1 {
    font-size: 8vw;
    margin-top: 0%;
    color: black;
  }
}

@media screen and (min-width: 701px) and (max-width: 1000px) {
  h1 {
    font-size: 8vw;
    margin-top: -2%;
    color: black;
  }
}
