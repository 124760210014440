.contact-container {
  width: 30%;
}
.login-box {
  width: 100%;
  padding: 40px;
  background: linear-gradient(45deg, black, rgb(44, 44, 44));
  box-sizing: border-box;
  box-shadow: 0 15px 25px rgba(0, 0, 0, 0.6);
  border-radius: 0.5vw;
}

.login-box h2 {
  font-size: var(--bigText);
  margin: 0 0 32px;
  padding: 0;
  color: #fff;
  text-align: center;
}

.login-box .user-box {
  position: relative;
}

.login-box .user-box input,
.login-box .user-box textarea {
  width: 100%;
  padding: 10px 0;
  font-size: var(--SmallText);
  font-weight: 100;
  color: #fff;
  margin-bottom: 32px;
  border: none;
  border-bottom: 1px solid #fff;
  outline: none;
  background: transparent;
}

.login-box .user-box textarea {
  resize: none;

}

.login-box .user-box label {
  position: absolute;
  top: 0;
  left: 0;
  padding: 10px 0;
  font-size: var(--SmallText);
  color: #fff;
  pointer-events: none;
  transition: 0.5s;
}

.login-box .user-box input:focus ~ label,
.login-box .user-box input:valid ~ label,
.login-box .user-box textarea:focus ~ label,
.login-box .user-box textarea:valid ~ label {
  top: -20px;
  left: 0;
  color: var(--secondColor);
  font-size: var(--SmallSmallText);
  font-weight: bold;
}

.login-box form button {
  cursor: pointer;
  color: #fff;
  position: relative;
  display: inline-block;
  padding: 8px 16px;
  background-color: rgba(255, 255, 255, 0);
  text-decoration: none;
  text-transform: uppercase;
  overflow: hidden;
  transition: 0.5s;
  letter-spacing: 4px;
  border-radius: 0.2rem;
  font-size: var(--regularText);
  padding: 1rem 1.5rem;
  background: linear-gradient(45deg, black, var(--lessBlack));
  
  border: 2px rgba(255, 255, 255, 0.069) solid;
  font-weight: 500;
  transition: all 0.5s;
}

.login-box button:hover {
  background: linear-gradient(
    54deg,
    var(--secondColor) 11%,
    var(--mainColor) 100%
  );
  color: #fff;
  box-shadow: -5px 5px 10px var(--secondColor), -5px 5px 20px var(--secondColor),
    5px -5px 20px var(--mainColor), 5px -5px 10px var(--mainColor);
}

.login-box button span {
  position: absolute;
  display: block;
}

.login-box button span:nth-child(1) {
  top: 0;
  left: -100%;
  width: 100%;
  height: 3px;
  background: linear-gradient(90deg, transparent, var(--mainColor));
  animation: btn-anim1 2s linear infinite;
}

@keyframes btn-anim1 {
  0% {
    left: -100%;
  }
  50%,
  100% {
    left: 100%;
  }
}

.login-box button span:nth-child(2) {
  top: -100%;
  right: 0;
  width: 3px;
  height: 100%;
  background: linear-gradient(180deg, transparent, var(--mainColor));
  animation: btn-anim2 2s linear infinite;
  animation-delay: 0.5s;
}

@keyframes btn-anim2 {
  0% {
    top: -100%;
  }
  50%,
  100% {
    top: 100%;
  }
}

.login-box button span:nth-child(3) {
  bottom: 0;
  right: -100%;
  width: 100%;
  height: 3px;
  background: linear-gradient(270deg, transparent, var(--secondColor));
  animation: btn-anim3 2s linear infinite;
  animation-delay: 1s;
}

@keyframes btn-anim3 {
  0% {
    right: -100%;
  }
  50%,
  100% {
    right: 100%;
  }
}

.login-box button span:nth-child(4) {
  bottom: -100%;
  left: 0;
  width: 3px;
  height: 100%;
  background: linear-gradient(360deg, transparent, var(--secondColor));
  animation: btn-anim4 2s linear infinite;
  animation-delay: 1.5s;
}

@keyframes btn-anim4 {
  0% {
    bottom: -100%;
  }
  50%,
  100% {
    bottom: 100%;
  }
}

input:-webkit-autofill,
input:-webkit-autofill:hover,
input:-webkit-autofill:focus,
input:-webkit-autofill:active {
  transition: background-color 5000s ease-in-out 0s;
  -webkit-text-fill-color: #fff !important;
}
.formButton-container {
  display: flex;
  justify-content: center;
}

.big-contact-container {
  width: 100vw;
  display: flex;
  align-self: center;
  justify-content: center;
  background: linear-gradient(
    170deg,
    var(--secondColor) 50%,
    rgba(0, 0, 0, 0.192) 90%
  );
  padding: 10rem 0rem 40rem 0rem;
}

@media screen and (min-width: 300px) and (max-width: 600px) {
  .contact-container {
    width: 95%;
  }

  .login-box form button {
    font-size: 1rem;
    padding: 1rem 1.2rem;
  }

  .login-box h2 {
    font-size: var(--bigText);
  }
}

@media screen and (min-width: 601px) and (max-width: 900px) {
  .contact-container {
    width: 80%;
  }

  .login-box form button {
    font-size: 1.5rem;
    padding: 1rem 1.2rem;
  }
}

@media screen and (min-width: 901px) and (max-width: 1200px) {
  .contact-container {
    width: 60%;
  }
}

@media screen and (min-width: 1201px) and (max-width: 2000px) {
  .contact-container {
    width: 50%;
  }
}

@media screen and (min-width: 2001px) {
  .contact-container {
    width: 40%;
  }
}

@media screen and (min-width: 300px) and (max-width: 1000px) {
  .big-contact-container {
    padding-bottom: 20rem;
  }
}
