.headingTopLeft {
  position: absolute;
  margin: 0;
  top: 2rem;
  left: 2rem;
  text-align: left;
  display: flex;
  flex-direction: row;
  z-index: 10;
  transition: all 1s;
}

.heading {
  margin: 0;
  text-align: left;
  display: flex;
  flex-direction: row;
  z-index: 0;
  transition: all 1s;
}

.portfolioTitleText {
  height: auto;
  display: flex;
  flex-direction: column;
}

.portfolioTitleImg {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 50px;
  height: auto;
  margin-right: 1rem;
}

.name,
.portfolio {
  margin: 0;
}

.name {
  display: block;
  color: rgb(255, 255, 255);
  font-size: 1.5rem;
  font-weight: 300;
}

.portfolio {
  font-weight: bold;
  font-size: 1.7rem;
  color: rgb(255, 255, 255);
  letter-spacing: 0.6rem;
}

@keyframes tilt-n-move-shaking {
  0% {
    transform: translate(0, 0) rotate(0deg);
  }
  25% {
    transform: translate(2px, 2px) rotate(3deg);
  }
  50% {
    transform: translate(0, 0) rotate(0deg);
  }
  75% {
    transform: translate(-2px, 2px) rotate(-3deg);
  }
  100% {
    transform: translate(0, 0) rotate(0deg);
  }
}

@media screen and (min-width: 1px) and (max-width: 500px) {
  .portfolio {
    font-size: 1.2rem;
  }
  .name {
    font-size: 1.2rem;
  }
  .portfolioTitleImg {
    width: 30px;
  }
}
