.LoadingAnimation {
  width: 100vw;
  height: 100vh;
  background-color: rgb(0, 0, 0);
  position: fixed;
  top: 0;
  left: 0;
  z-index: 300000000;
  overflow: hidden;
  display: flex;
  justify-content: center;
  align-items: center;
}

.LoadingAnimation-logo {
  width: 50vw;
  height: 50vw;
  position: relative;
}

.LoadingAnimation-box {
  position: absolute;
  width: 20%;
  padding-bottom: 20%;
  border-radius: 1vw;
}

.LoadingAnimation-green {
  background-color: var(--secondColor);
  top: 37%;
  left: 37%;
}

.LoadingAnimation-purple {
  background-color: var(--mainColor);
  top: 43%;
  left: 43%;
}

.LoadingAnimation-white {
  background-color: white;
  top: 41%;
  left: 41%;
  position: absolute;
  width: 18%;
  padding-bottom: 18%;
}
