.MyWebsite-container {
  
  padding: 5rem 10rem;
}

.MyWebsite-container h3 {
  font-size: var(--bigText);
  margin: 0%;
  margin-top: 3rem;
  margin-bottom: 1rem;
}

.MyWebsite-container p,
.MyWebsite-container q,
.MyWebsite-container ul li {
  font-size: var(--regularText);
  font-weight: var(--regularWeight);
  margin: 0;
  padding: 0;
}

.MyWebsite-container ul li {
  margin-bottom: 0.5rem;
}

.MyWebsite-container ul {
  margin: 1rem 0rem;
}

.MyWebsite-inspiration-container,
.MyWebsite-userfeedback-container,
.MyWebsite-figmaMockup-container {
  display: flex;
  gap: 5%;
  justify-content: space-between;
  align-items: center;
}

.MyWebsite-userfeedback-container {
  margin-bottom: 2rem;
}

.MyWebsite-inspiration-firstTitle {
  margin: 0rem;
}

.MyWebsite-container address {
  font-size: var(--regularBigText);
  display: flex;
  align-self: center;
  justify-content: center;
  flex-direction: column;
  margin-top: 1rem;
}

.MyWebsite-quote-container {
  display: flex;
  align-self: center;
  justify-content: center;
  flex-direction: column;
  padding: 3vw 20%;
}

.MyWebsite-quote-container q {
  font-style: italic;
}

.noMarginTop {
  margin-top: 0 !important;
}

.centerDiv {
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
}

.imgMargin {
  margin: 5rem rem;
  
}
.portfolio-img-1 img,
.portfolio-img-1 img,
.portfolio-img-2 img,
.portfolio-img-3 img,
.portfolio-img-4 img {
  margin: 2rem 0rem;
}
.portfolio-img-fullsize{
  max-width: 100%;
    max-height: 100%;

}
.portfolio-img-fullsize img{
  width: 100%;

}

.project-link-container{

}
.project-link {
  font-size: var(--bigText);
  color: black;
}

.project-link-container {
  transition: transform 0.3s ease;
  transform-origin: center top;
}

.project-link-container:hover {
  transform: scale(1.02);
}

.container-divide{
  display: flex;
  justify-content: space-evenly;
  align-items: center;
  

}
.container-left{
  width: 60%;
  /* display: flex;
  justify-content: center;
  align-items: center; */
}
.container-right{
  width: 40%;
  display: flex;
  justify-content: center;
  align-items: center;
}
.responsive-iframe {
  position: relative;
  padding-bottom: 56.25%; /* 16:9 aspect ratio (height divided by width) */
  height: 0;
  overflow: hidden;
  max-width: 100%;
}

.responsive-iframe iframe {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
}
@media screen and (min-width: 300px) and (max-width: 500px) {
   .MyWebsite-container {
    padding: 5rem 1.5rem;
  }
}
@media screen and (min-width: 501px) and (max-width: 1200px) {
  .MyWebsite-container {
    padding: 5rem 3rem;
  }
}
@media screen and (min-width: 300px) and (max-width: 1200px) {
 
  .MyWebsite-inspiration-container,
  .MyWebsite-userfeedback-container,
  .MyWebsite-figmaMockup-container {
    flex-direction: column;
  }

  .portfolio-img-1,
  .portfolio-img-2,
  .portfolio-img-3,
  .portfolio-img-4 {
    max-width: 100%;
    max-height: 100%;
  }
  
  .portfolio-img-1 img,
  .portfolio-img-2 img,
  .portfolio-img-3 img,
  .portfolio-img-4 img {
    width: 100%;
  }
}
