.goals-container {
  position: relative;
  margin-top: 40rem;
}

.goals-svgImage-top {
  transform: rotate(180deg);
  width: 100%;
  background-repeat: no-repeat;
  background-position: center;
  background-size: cover;
  position: absolute;
  height: 300px;
  margin-top: -300px;
}

.goals-main {
  background: linear-gradient(
    175deg,
    var(--secondColor) 60%,
    var(--mainBackground)
  );
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  width: 100%;
  height: auto;
  padding: 10rem 0rem;
  padding-bottom: 30rem;
}

.goals-main-title {
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 1rem;
}

.goals-main h3 {
  margin: 0rem;
  text-shadow: none;
  margin-top: 0;
  margin-bottom: 3rem;
  font-size: var(--veryBigText);
  font-weight: 300;
  padding-right: 1rem;
}

.goals-main-growup {
  font-weight: 1000;
}

.goals-main h4 {
  margin-top: 0;
  font-size: var(--bigText);
  margin-bottom: 0rem;
}

.goals-main ul {
  display: flex;
  flex-direction: column;
  justify-content: center;
  width: 70%;
}

.goals-main ul li {
  font-size: var(--SmallText);
  line-height: 2.2rem;
}

.goals-text-text {
  padding: 0rem 5vw 0rem 5vw;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

.goals-svgImage-bot {
  width: 100%;
  background-repeat: no-repeat;
  background-position: center;
  background-size: cover;
  position: absolute;
  height: 300px;
  transform: rotate(180deg);
  margin-top: -300px;
}

@media screen and (min-width: 300px) and (max-width: 600px) {
  .goals-main ul {
    width: 95%;
  }
}

@media screen and (min-width: 300px) and (max-width: 1000px) {
  .goals-container {
    margin-top: 20rem;
  }

  .goals-main {
    padding-bottom: 20rem;
  }
}
