.me-main {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  height: auto;
  text-shadow: none;
  background: linear-gradient(175deg, white 80%, black);
  position: relative;
  padding-bottom: 10rem;
}

.me-container {
  width: 100%;
  display: flex;
  margin-top: 10rem;
  justify-content: center;
  align-items: center;
}

.me-img-container {
  position: relative;
  border-radius: 0.5rem;
  display: flex;
  justify-content: center;
  align-items: center;
  width: 40%;
}

.me-img {
  width: 100%;
  max-width: 600px;
  height: auto;
  border-radius: 1vw;
  z-index: 5;
  box-shadow: rgb(49, 49, 49) 10px 10px 20px;
}

.me-text-container {
  width: 60%;
}

.me-text-container h3 {
  margin-top: 0;
  margin-bottom: 0rem;
  font-size: var(  --veryBigText);
  text-shadow: none;
  margin-bottom: 3rem;
}

.me-text-container h4 {
  margin-top: 0;
  font-size: var(--bigText);
  margin-bottom: 0rem;
}

.me-text-container p {
  font-size: var(--SmallText);
  line-height: 1.8rem;
}

.me-text-container h5 {
  font-size: var(--regularText);
  margin-bottom: 1rem;
  margin-top: 3rem;
  font-weight: var(--strongWeight);
}

.me-links-container {
  width: 100%;
  display: flex;
  justify-content: center;
}

.me-links {
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 5%;
  margin-top: 5rem;
  margin-bottom: 10rem;
  white-space: nowrap;
}

.me-links-container h4 {
  margin-top: 0;
  font-size: var(--bigText);
  margin-bottom: 0rem;
}

.me-links-a {
  font-size: var(--regularText);
  padding: 1rem 1.5rem;
  background: linear-gradient(45deg, black, var(--lessBlack));
  border-radius: 0.2rem;
  box-shadow: rgb(49, 49, 49) 5px 5px 10px;
  border: 2px rgba(255, 255, 255, 0.069) solid;
  font-weight: 500;
  transition: all 0.3s;
  letter-spacing: 4px;
}

.me-links-a:hover {
  transform: scale(1.1);
}

.me-icon {
  width: 100px;
  height: 100px;
  fill: black;
}

.me-icon-alignment {
  display: flex;
  gap: 2rem;
}
.me-icon-container {
  display: flex;
  justify-content: center;
  align-items: center;
}

.me-top-section {
  display: flex;
  gap: 5rem;
  align-items: center;
}

.me-top-section-text {
  display: flex;
  justify-content: center;
  align-items: flex-start;
  flex-direction: column;
  width: 60%;
}

@media screen and (min-width: 300px) and (max-width: 1200px) {
  .me-top-section {
    flex-direction: column;
  }
  .me-designer,
  .me-frontend,
  .me-cognitive,
  .me-musician {
    flex-direction: column;
    margin-top: 5rem;
  }
  .me-icon-alignment {
    gap: 0rem;
  }
  .me-img-container {
    width: 50%;
  }
  .me-top-section-text {
    width: 100%;
  }
}

@media screen and (min-width: 300px) and (max-width: 700px) {
  .me-links {
    flex-direction: column;
    gap: 1rem;
    width: 60%;
  }

  .me-text-container h3 {
    font-size: 3rem;
  }

  .me-links-a {
    display: flex;
    align-items: center;
    justify-content: center;
    width: 100%;
    
  }
  
  .me-links-a {
    font-size: var(--SmallText);
    padding: 0.6rem 2rem;
   
  }
}

@media screen and (min-width: 300px) and (max-width: 1200px) {
  .me-text-container {
    width: 90%;
  }
}
