.MainContent {
  width: 100%;
  height: auto;
  display: flex;
  justify-content: center;
  padding-top: 20rem;
  z-index: 0;
  margin: 0;
  position: relative;
  flex-direction: column;
  background: rgb(0, 0, 0);
  
}
