footer {
  overflow: hidden;
  height: 70vh;
  background: linear-gradient(45deg, rgb(0, 0, 0), var(--lessBlack));
  color: white;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  letter-spacing: 0.1rem;
  z-index: 0;
  padding-top: 10rem;
  padding-bottom: 5rem;
}

footer img {
  width: 2rem;
  height: 2rem;
}

.footer-content {
  width: 80%;
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: var(--SmallSmallText);
  gap: 5vw;
  margin-top: 5rem;
}

.footer-content h3 {
  font-weight: var(--regularWeight);
}

footer h5 {
  font-size: var(--SmallSmallText);
  font-weight: var(--regularWeight);
}

.footer-content-right a {
  font-size: var(--SmallText);
  color: white;
  text-decoration: none;
  display: flex;
  align-items: center;
  gap: 1rem;
}

.footer-bold {
  font-weight: var(--strongWeight);
}

footer h4 {
  font-size: var(--SmallSmallText);
  letter-spacing: 0.2rem;
  font-weight: var(--regularWeight);
  padding: 1rem;
}

.footer-contactMe:hover {
  transform: scale(1.05);
}

.footer-contactMe {
  transition: transform 0.5s;
  text-decoration: none;
  color: white;
  font-size: var(--regularText);
}

@media screen and (min-width: 300px) and (max-width: 800px) {
  .footer-content {
    flex-direction: column;
  }

  footer {
    padding-bottom: 15rem;
  }
}

