

.mailSent-backdrop {
  width: 100vw;
  height: 100vh;
  background-color: rgba(0, 0, 0, 0.8);
  position: fixed;
  top: 0;
  left: 0;
  z-index: 20000001;
  display: flex;
  justify-content: center;
  align-items: center;
}

.mailSent-modal {
  padding: 3rem 2rem;
  font-size: var(--bigText);
  background: linear-gradient(
    39deg,
    var(--secondColor),
    var(--secondBackground)
  );

  border-radius: 0.5rem;
  color: white;
  text-shadow: inset 5px 5px 5px;
  display: flex;
  font-weight: var(--strongWeight);
  justify-content: center;
  align-items: center;
  animation: modal-bounce 3s;
  z-index: 20000001;
}

.mailSent-modal h3{
  margin: 0;
}

@keyframes modal-bounce {
  0% {
    transform: translateX(-200%);
    opacity: 0;
  }
  30% {
    transform: translateX(0%);
    opacity: 1;
  }
  70% {
    transform: translateX(0%);
    opacity: 1;
  }
  100% {
    transform: translateX(200%);
    opacity: 0;
  }
}
