.ImgBoardContainer {
  width: 95%;
  height: auto;
  margin: 0;
  font-size: 4.5rem;
  display: flex;
  flex-direction: column;
  z-index: 10;
  gap: 10rem;
  background: linear-gradient(170deg, black 70%, white 90%);
  margin-top: 20rem;
  position: relative;
  padding: 2.5%;
  padding-bottom: 30rem;

  
}

.imgContainer{
  position: relative;
}

.imgTitle1{
  margin: 0;
  color: white;
  position: absolute;
  top: 50%;
  left: 23%;
  display: flex;
  flex-direction: column;
  align-items: center;
  font-size: 3vw;
  gap: 1vw;
}

.img-center-title {
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
}

.imgTitle2 {
  margin: 0;
  color: white;
  position: absolute;
  top: 55%;

  right: auto;
  display: flex;
  flex-direction: column;
  align-items: center;
  font-size: 3vw;
  gap: 1vw;
}

.imgTitle3{
  margin: 0;
  color: white;
  position: absolute;
  top: 13%;
  right: auto;
  display: flex;
  flex-direction: column;
  align-items: center;
  font-size: 3vw;
  gap: 1vw;
}

.imgTitle4{
  margin: 0;
  color: white;
  position: absolute;
  top: 10%;
  left: 20%;
  right: auto;
  display: flex;
  flex-direction: column;
  align-items: center;
  font-size: 3vw;
  gap: 1vw;
}

.imgTitle1-row1 {
  font-weight: 300;
  letter-spacing: 0.4rem;
}

.imgTitle1-row3 {
  font-size: 2vw;
  font-weight: 300;
  letter-spacing: 0.4rem;
}

.imgTitle2-row1 {
  font-weight: 300;
  letter-spacing: 0.4rem;
}

.imgTitle2-row3 {
  font-size: 2vw;
  font-weight: 300;
  letter-spacing: 0.4rem;
}

.notBold {
  font-weight: 300;
}

.bold {
  font-weight: 600;
}

.imgTitle3-row1 {
  font-weight: 300;
  letter-spacing: 0.4rem;
}
.imgTitle4-row3{
  font-size: 2vw;
  font-weight: 300;
  letter-spacing: 0.4rem;
}
.images{
  display: flex;
  flex-direction: column;
  gap: 2vw;
}
@media screen and (min-width: 501) and (max-width: 1200) {

}

@media screen and (min-width: 300px) and (max-width: 501px) {
  .ImgBoardContainer {
    width: 95%;
    padding: 2.5%;
    padding-bottom: 20rem;
  }

  .imgTitle1,
  .imgTitle2,
  .imgTitle3{
    font-size: 5vw;
  }
  .imgTitle4{
    font-size: 4vw;
  }

  .imgTitle1-row1,
  .imgTitle1-row3,
  .imgTitle2-row1,
  .imgTitle1-row3,
  .imgTitle3-row1,
  .imgTitle4-row1,
  .imgTitle4-row2{
    letter-spacing: 0.1rem;
  }

  .imgTitle1-row3,
  .imgTitle2-row3,
  .imgTitle4-row3{
    font-size: 3vw;
    letter-spacing: 0.1rem;
  }

  .imgTitle1 {
    left: 5%;
  }
  .imgTitle4{
    left: auto;
    top: 10%;
  }
}
