.sliderTimeline-container {
  padding-top: 10rem;
}

.sliderTimeline-container h3 {
  color: white;
  font-size: var(--veryBigText);
  margin-bottom: 2rem;
  margin-top: 0;
  text-shadow: 1px 1px 7px black;
  display: flex;
  justify-content: center;
}

.sliderTimeline {
  width: 100%;
  background-color: white;
  width: 80%;
  margin: 0rem auto;
  color: rgb(0, 0, 0);
  border-radius: 0.5vw;
  position: relative;
  box-shadow: rgba(0, 0, 0, 0.708) 8px 8px 20px;
}

.sliderTimeline-dates {
  display: flex;
  gap: 0rem 10vw;

  font-size: var(--regularText);
}

.sliderTimeline-dates h4 {
  font-weight: var(--regularWeight);
  margin: 1rem;

}

.sliderTimeline h3 {
  font-size: var(--bigText);
}

.slider-timeline-center {
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  width: 100%;
}

.sliderTimeline-slider {
  margin: 2rem;
  margin-top: 0rem;
  width: 50%;
  height: 20px;
  -webkit-appearance: none;
  outline: none;
  border-radius: 15px;
  overflow: hidden;
  box-shadow: inset 0 0px 3px black;
  overflow: hidden;
  -webkit-appearance: none;
  background-color: rgb(209, 209, 209);
}

.sliderTimeline-slider::-webkit-slider-runnable-track {
  height: 10px;
  -webkit-appearance: none;
  margin-top: -11px;
  cursor: pointer;
}

.sliderTimeline-slider::-webkit-slider-thumb {
  -webkit-appearance: none;
  margin-right: -10px;
  cursor: ew-resize;
  background: #848484;
  box-shadow: -610px 0 0 600px var(--secondColor);
  border-radius: 50%;
  width: 20px;
  height: 20px;
  border: 4px solid rgba(114, 114, 114, 0.838);
}

.sliderTimeline-slider::-moz-range-progress {
  height: 20px;
  -webkit-appearance: none;
  margin-top: -11px;
  cursor: pointer;
  background-color: var(--secondColor);
}

.sliderTimeline-slider::-moz-range-thumb {
  -webkit-appearance: none;
  margin-right: -10px;
  cursor: ew-resize;
  background: #848484;
  box-shadow: -610px 0 0 600px var(--secondColor);
  border-radius: 50%;
  width: 12px;
  height: 12px;
  border: 4px solid rgba(114, 114, 114, 0.838);
}

@media screen and (min-width: 300px) and (max-width: 800px) {
  .sliderTimeline {
    width: 95%;
  }
}
@media screen and (min-width: 801px) and (max-width: 1200px)  {
  .sliderTimeline {
    width: 80%;
  }
}



@media screen and (min-width: 1201px)  {
  .sliderTimeline {
    width: 70%;
  }
}
