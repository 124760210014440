.ImgBoard-div {
  display: flex;
  justify-content: center;
  flex-direction: column;
  align-items: center;
  
}

.ImgBoardImg {
  max-width: 90%;
  border: 2vw solid rgb(255, 255, 255);
  max-height: 75vh;
  position: relative;
  border-radius: 0.5vw;
  object-fit: cover;
   box-shadow: rgba(0, 0, 0, 0.708) 8px 8px 20px;

}

.ImgBoardImgNoTopBorder {
  max-width: 80%;
  border: 2vw solid rgb(255, 255, 255);
  border-top: 0rem;
}
