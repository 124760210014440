@import "https://fonts.googleapis.com/css?family=Lato";

.arrow {
  width: 2.5rem;
  height: 2.5rem;
  display: inline-block;
  position: relative;
  margin: 0 2rem;
}

.arrow span {
  top: 1rem;
  position: absolute;
  width: 1.5rem;
  height: 0.2rem;
  background-color: #b1b1b1;
  display: inline-block;
  transition: all 0.2s ease;
}

.arrow span:first-of-type {
  left: 0;
  transform: rotate(45deg);
}

.arrow span:last-of-type {
  right: 0;
  transform: rotate(-45deg);
}

.down span:first-of-type {
  transform: rotate(-45deg);
}

.down span:last-of-type {
  transform: rotate(45deg);
}
