.IntroBackground {
  position: relative;
  width: 100vw;
  height: 100vh;
}

.topShadow {
  width: 100vw;
  height: 20vh;
  background: linear-gradient(
    to bottom,
    rgba(0, 0, 0, 0.822) 0%,
    rgba(0, 0, 0, 0.738) 19%,
    rgba(0, 0, 0, 0.541) 34%,
    rgba(0, 0, 0, 0.382) 47%,
    rgba(0, 0, 0, 0.278) 56.5%,
    rgba(0, 0, 0, 0.194) 65%,
    rgba(0, 0, 0, 0.126) 73%,
    rgba(0, 0, 0, 0.075) 80.2%,
    rgba(0, 0, 0, 0.042) 86.1%,
    rgba(0, 0, 0, 0.021) 91%,
    rgba(0, 0, 0, 0.008) 95.2%,
    rgba(0, 0, 0, 0.002) 98.2%,
    transparent 100%
  );
}

@media screen and (min-width: 300px) and (max-width: 500px) {
  .IntroBackground {
    height: 80vh;
  }
}
