.quote-div {
  position: relative;
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  margin-top: 10rem;
  margin-bottom: 30rem;
  color: rgb(0, 0, 0);
}

.qoute-logo {
  position: absolute;
  width: calc(40% + 2rem);
  border-radius: 1vw;
  box-shadow: rgb(49, 49, 49) 10px 10px 20px;
}

.qoute-green {
  background-color: var(--secondColor);
  margin-top: -2.5%;
  margin-right: 5%;
}

.qoute-purple {
  background-color: var(--mainColor);
  margin-top: 2.5%;
  margin-right: -5%;
}

.quote {
 
  background-color: white;
  z-index: 2;
  padding: 2rem;
  display: flex;
  flex-direction: column;
  box-shadow: rgb(49, 49, 49) 10px 10px 20px;
  border-radius: 0.5rem;
}

.quote-bot {
  display: flex;
  justify-content: center;
  flex-direction: column;
  align-items: center;
  margin: 0;
}

.quote p {
  font-size: var(--SmallText);
  line-height: 2.2rem;
}

.quote h4 {
  margin: 0;
  margin-top: 3rem;
  font-size: var(--regularText);
}

.quote h5 {
  font-size: var(--regularText);
  margin: 1rem;
  color: rgb(52, 52, 52);
}

.bold {
  font-weight: var(--strongWeight);
}

.quote-icon {
  display: flex;
  align-items: center;
  justify-content: center;
 
}

@media screen and (min-width: 300px) and (max-width: 500px) {
  .quote {
    padding: 1.5rem;
    width: 80%;
  }

  .qoute-logo {
    position: absolute;
    width: calc(50% + 1rem);
    border-radius: 1vw;
    height: auto;
    box-shadow: rgb(49, 49, 49) 10px 10px 20px;
  }

  .qoute-green {
    display: none;
  }

  .qoute-purple {
    display: none;
  }
}

@media screen and (min-width: 501px) and (max-width: 800px) {
  .quote {
    padding: 1rem;
    width: 50%;
  }

  .qoute-logo {
    position: absolute;
    width: calc(50% + 1rem);
    border-radius: 1vw;
    height: auto;
    box-shadow: rgb(49, 49, 49) 10px 10px 20px;
  }

  .qoute-green {
    background-color: var(--secondColor);
    margin-top: -5%;
    margin-right: 10%;
  }

  .qoute-purple {
    background-color: var(--mainColor);
    margin-top: 5%;
    margin-right: -10%;
  }
}

@media screen and (min-width: 801px) {
  .quote {
    padding: 2rem;
    width: 40%;
  }
}

@media screen and (min-width: 300px) and (max-width: 1000px) {
  .quote-div {
    margin-top: 0;
    margin-bottom: 10rem;
  }
}
