.projectContainer {
  width: 100%;
  height: auto;
  margin: 0;
  display: flex;
  flex-direction: column;
  z-index: 10;
  padding-bottom: 40rem;
  background-color: var(--secondColor);
  position: relative;
  margin-top: 40rem;
  padding-top: 5rem;
}

.projectContainertitle {
  color: white;
  font-size: var(--veryBigText);
  margin-bottom: 2rem;
  text-shadow: 1px 1px 7px black;
  display: flex;
  justify-content: center;
}
.moreProjects{
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  font-size: var(--regularText);
  padding-top: 5rem;
  color: var(--lessBlack);
}
@media screen and (min-width: 300px) and (max-width: 1000px) {
  .projectContainer {
    margin-top: 20rem;
    padding-bottom: 20rem;
  }
}

.project-column{
/*   display: flex;
  margin: 5rem;
  flex-direction: column;
  gap: 5rem; */
}